.accountWrapper {
    height: 100vh;
}

.accountInner {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.right-section {
    width: 560px;
    height: 100%;
    border-left: 1px solid #01bade;
}

.page-title {
    font-size: 25px;
    line-height: 1.4;
    margin-bottom: 15px;
    font-family: "helvetica_neue66MdIt";
    font-weight: 700;
    letter-spacing: 0.5px;
}

.page-description {
    font-size: 18px;
    line-height: 1.4;
    text-align: center;
    letter-spacing: 0.5px;
    color: #000000;
}

.right-inner {
    height: 100%;
    padding: 0 50px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.input-row {
    width: 100%;
    margin-bottom: 25px;
    position: relative;
}

.form-wrapper {
    width: 100%;
    padding: 25px 0 0;
}

.MuiTextField-root {
    width: 100%;
}

.forgot-link a {
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: -0.139131px;
    text-decoration-line: underline;
    color: #333;
    transition: 0.3s ease-in;
}

.forgot-link a:hover {
    color: #01bade;
}

.forgot-link {
    text-align: right;
    padding: 5px 0;
}
.eye-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.password-row {
    width: 100%;
    position: relative;
}

.right-section .input-error fieldset {
    border-color: #ff0000 !important;
}

.right-section .input-error label {
    color: #ff0000;
}

.form-group {
    position: relative;
}
.login-btn {
    margin: 15px 0;
}
.login-btn button {
    width: 100%;
    padding: 10px 15px;
}
.normal-btn .MuiButtonBase-root.MuiButton-root {
    font-family: "helvetica_mediumregular";
}
/* Responsive for Tablet View */
@media (max-width: 1024px) {
    .accountInner .right-section {
        width: 55%;
    }
    .right-inner {
        padding: 0 25px;
    }
    .right-section .right-inner .page-title {
        font-size: 28px;
        line-height: 20px;
    }
    .right-section .right-inner .page-description {
        text-align: left;
        font-size: 22px;
    }
    .right-section .right-inner .form-wrapper .input-row {
        margin-bottom: 30px;
    }
    .right-section .right-inner .form-wrapper .password-row {
        margin-bottom: 10px;
    }
    .right-section .right-inner .form-wrapper .btn-wrap {
        margin-top: 25px;
    }
    .right-section .right-inner .form-wrapper .btn-wrap button {
        width: 100%;
        padding: 15px 0;
    }
}

/* Responsive for Mobile View */
@media (max-width: 767px) {
    .accountInner .right-section {
        width: 100%;
        height: calc(100% - 98px);
    }
    .right-section .right-inner {
        padding: 0 20px;
    }
    .right-section .right-inner .page-title {
        font-size: 25px;
        line-height: 24px;
        margin-bottom: 10px;
    }
    .right-section .right-inner .page-description {
        font-size: 20px;
        line-height: 25px;
    }
    .right-section .right-inner .form-wrapper .input-row {
        margin-bottom: 30px;
    }
    .right-section .right-inner .form-wrapper .password-row {
        margin-bottom: 10px;
    }
    .right-section .right-inner .form-wrapper .btn-wrap {
        margin-top: 25px;
    }
    .right-section .right-inner .form-wrapper .btn-wrap button {
        width: 100%;
        padding: 15px 0;
        font-size: 16px;
    }
}
