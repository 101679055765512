.not-search-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 25px;
    background: #fff;
    color: #fc0000;
}
.not-search-found svg{
    width:50px;
    height:50px;
}
.not-search-found h3{
    font-size: 20px;
    color: #fc0000;
    margin: 10px 0;
}