.fan-wrap,
.user-wrap {
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    background-color: #fff;
}
.fan-title {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btn-group {
    padding: 0;
}
.btn-group button {
    margin-right: 15px;
}
.user-inner {
    padding-top: 15px;
}
.user-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.user-inner-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -8px;
}
.user-col {
    max-width: 33.33%;
    flex: 0 33.33%;
    padding: 0 8px;
}
.user-col.top-spacing,
.user-bio-col.top-spacing,
.user-info-col.top-spacing,
.sport-info.top-spacing {
    margin-top: 15px;
}
.subscription-link {
    margin-top: 8px;
}
.subscription-link a {
    color: #4ac1e0;
    font-size: 15px;
    text-decoration: none;
}
.subscription-link a:hover {
    text-decoration: underline;
}
.user-profile-inner {
    padding-top: 15px;
    display: flex;
}
.user-detail {
    width: 100%;
    margin-bottom: 6px;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.7px;
}
.user-inner p {
    font-size: 15px;
    font-weight: 400;
    color: #000;
    line-height: 1.5;
}
.user-image {
    width: 160px;
    height: 160px;
    flex: 0 0 160px;
    /* border-radius: 50%; */
    overflow: hidden;
}
.user-image img,
.cover-img img,
.user-image figure {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
label.user-detail.bottom-spacing {
    margin-bottom: 8px;
}
.user-left {
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.user-right {
    width: calc(100% - 180px);
}
.user-info-col {
    max-width: 49.99%;
    flex: 0 49.99%;
    padding: 0 8px;
}
.user-right.margin,
.user-inner-row.margin {
    margin-top: 65px;
}
.user-bio-col {
    max-width: 100%;
    flex: 0 100%;
    padding: 0 8px;
}
.user-bio.top-spacing,
.user-col-bio.top-spacing {
    margin-top: 15px;
}
.user-col-bio {
    max-width: 66.66%;
    flex: 0 66.66%;
    padding: 0 8px;
}
.cover-img {
    /* width: 320px; */
    overflow: hidden;
}
.cover-img img {
    width: 100%;
    height: 180px;
    object-fit: cover;
}
.sport-title-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.like-title {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    color: #000;
}
.like-col {
    max-width: 16.66%;
    flex: 0 16.66%;
    padding: 0 15px;
}
.info-text {
    margin-top: 15px;
}
.info-text a {
    color: #4ac1e0;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    transition: 0.3s ease-in;
}
.info-text a:hover {
    text-decoration: underline;
}
.user-top-spacing {
    margin-top: 25px;
}
.followers-title {
    font-size: 17px;
    font-family: "helvetica_neue66MdIt";
    letter-spacing: 0.5px;
    font-weight: 700;
}
.user-col-half p {
    line-height: 1.5;
}
.sport-logo.like {
    width: 40px;
    height: 40px;
    padding: 7px;
    flex: 0 0 40px;
    background: rgb(128 128 128 / 32%);
    overflow: hidden;
    border-radius: 3px;
}
.bio-description {
    word-break: break-all;
    line-height: 1.5;
}
.like-sport-text {
    margin-left: 8px;
}
