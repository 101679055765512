.policys-wrapper-aboutus {
    position: relative;
    min-height: 100vh;
    padding: 0;
    background-color: #000000;
}
.policys-wrapper-aboutus .policys-inner {
    padding: 15px;
}
.aboutus-footer-img {
    position: absolute;
    bottom: 0;
    right: 0;
}
.aboutus-footer-img figure {
    width: 100%;
    height: 600px;
}
.aboutus-footer-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.policys-wrapper-aboutus p strong {
    font-weight: bold;
    letter-spacing: 1.7px;
}
.policys-wrapper-aboutus p em {
    font-style: italic;
}
.policys-wrapper-aboutus p {
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    color: #fff;
}

.policys-wrapper-aboutus ul {
    padding-left: 25px !important;
    list-style: inside !important;
    color: #fff !important;
}

.policys-wrapper-aboutus ul li {
    color: #fff !important;
}

.policys-wrapper-aboutus ol {
    padding-left: 25px !important;
    list-style: decimal !important;
    color: #fff !important;
}
.aboutus-footer-img {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}
.aboutus-footer-img figure {
    width: 100%;
}
.aboutus-footer-img figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.aboutus-footer-img-mb {
    display: none;
}

@media screen and (max-width: 767px) {
    .policys-wrapper .loader-wrap {
        background: #000;
    }
    .aboutus-footer-img.d-none {
        display: none;
    }
    .aboutus-footer-img-mb {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
    }
    .aboutus-footer-img-mb figure img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .aboutus-footer-img-mb figure {
        width: 100%;
        height: 410px;
    }
    .aboutus-footer-img-mb {
        display: block;
    }
}
