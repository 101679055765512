.faq-wrapper .cms-content {
    border: solid 1px #d8d8d8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.faq-wrapper .cms-header {
    padding: 10px 15px;
    border-bottom: solid 1px #d8d8d8;
    background-color: rgba(128, 128, 128, 0.04);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.faq-wrapper .cms-header h2 {
    font-size: 17px;
    font-weight: 700;
    line-height: 25px;
    color: #000;
    font-family: "helvetica_neue66MdIt";
    letter-spacing: 0.5px;
}
.faq-wrapper .cms-header a {
    color: #4ac1e0;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
}
.faq-wrapper .cms-header a:hover {
    text-decoration: underline;
}
.faq-wrapper .content-description {
    padding: 15px;
}
.faq-wrapper .content-description p {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    color: #000000;
    word-break: break-all;
}
.faq-wrapper .content-description p:last-child {
    margin-bottom: 0;
}
.faq-content {
    padding: 15px 8px 15px 15px;
    border: solid 1px #d8d8d8;
    margin-bottom: 15px;
    border-radius: 5px;
}
.faq-content:last-child {
    margin-bottom: 0;
}
.faq-content .content-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 5px;
}
.faq-content .content-header h3 {
    display: flex;
    margin-bottom: 10px;
    font-size: 14px;
    color: #000000;
    font-weight: 700;
    line-height: 1.2;
}
.faq-content .content-header p strong {
    font-weight: bold;
}
.faq-content .content-header p em {
    font-style: italic;
}
.faq-content .content-header p {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    color: #000000;
}

.faq-content .content-header p ul {
    padding-left: 25px !important;
    list-style: inside !important;
}

.faq-content .content-header p ol {
    padding-left: 25px !important;
    list-style: decimal !important;
}
.faq-content .content-header p {
    font-weight: 700;
}
