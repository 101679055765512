.loader-wrap {
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader-relative{
    position: relative;
}
.loader-fixed{
    position: fixed;
}
.loader-inner {
    width: 100px;
    height: 100px;
    margin: 0 auto;
}
.loader-wrap .MuiCircularProgress-root {
    width: 100px;
    height: 100px;
    color: #fff !important;
}
