.video-feed-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btn-group button {
    margin-right: 15px;
}
.athlete-video-wrapper {
    width: 100%;
    height: 520px;
    margin: 15px 0;
    position: relative;
}
.athlete-video-wrapper figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.athlete-video-wrapper video {
    width: 100%;
    height: 100%;
    /* object-fit: cover;
    object-position: center center; */
}
.zoom-icon {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
}
.athlete-video-description p {
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 0.361px;
    color: #000000;
    opacity: 0.7;
}
.athlete-video-info-section {
    padding: 25px 0 5px;
}
.flex-row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.flex-col {
    max-width: 33.33%;
    flex: 0 33.33%;
    padding: 0 15px 10px;
}
.video-info-section label {
    font-size: 15px;
    font-weight: 700;
}
.athlete-video-detail.table-wrapper td:nth-of-type(2),
.athlete-video-detail.table-wrapper th:nth-of-type(2) {
    text-align: center;
}
.athlete-video-detail.table-wrapper thead th:last-child {
    width: 60px;
    text-align: left;
}
.athlete-video-detail.table-wrapper td:last-child,
.athlete-video-detail.table-wrapper th:last-child {
    width: 60px;
    text-align: left;
}
.athlete-video-info-section a {
    color: #4ac1e0;
    font-weight: 500;
    text-decoration: none;
}
.athlete-video-info-section a:hover {
    text-decoration: underline;
}
.athlete-video-detail .search-filter-wrap {
    margin-bottom: 15px;
}
