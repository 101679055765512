.btn-row {
    display: flex;
    flex-wrap: wrap;
}
.notification-info {
    width: 100%;
}
.notification-text {
    padding-top: 15px;
}
.notification-text.detail label.form-label,
.notification-description.detail label.form-label {
    font-weight: 700;
    letter-spacing: 1.5px;
}
.notification-text p,
.description-info p {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.4;
}
.notification-description p {
    margin-bottom: 15px;
    font-size: 14px;
}
p.detail-title {
    margin-bottom: 0;
}
.notification-text.detail {
    padding-top: 0;
}
.upload-image {
    width: 214px;
    height: 260px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}
.upload-image figure,
.upload-image figure img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.upload-image .upload-icon {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}
.upload-image .close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
}
.close-icon .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #fff;
    color: red;
    transition: all 0.3s ease-in;
    cursor: pointer;
}
.close-icon:hover svg {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-icon svg {
    padding: 4px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #d8d8d8;
    color: #4ac1e0;
    transition: all 0.3s ease-in;
}
.upload-icon:hover svg {
    padding: 4px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.notification-upload-file {
    width: 214px;
    height: 260px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    background-color: #f2f2f2;
    border: 1px dashed #000000;
}
.notification-upload-file img {
    margin-bottom: 10px;
}
.notification-upload-file input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}
.notification-upload-file .upload-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.upload-wrap {
    display: flex;
}
.notification-description {
    margin-bottom: 15px;
}
.notification-info .note {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.266px;
    color: #000000;
}
.form-row {
    width: 100%;
    margin: 0 -15px 15px;
    display: flex;
    align-items: center;
}
.col-half {
    max-width: 70%;
    flex: 0 70%;
    padding: 0 15px;
}
.breadcrumb-left {
    display: flex;
    align-items: center;
}
.breadcrumb-left a:hover svg {
    color: #677381;
}
.breadcrumb-left a svg {
    width: 20px;
    height: 17px;
    margin-right: 3px;
    vertical-align: middle;
    color: #000;
}
.other-field {
    margin-top: 9px;
    display: flex;
    align-items: center;
}
.add-athlete .table-wrapper thead th:last-child {
    width: 90px;
}
.add-athlete .table-wrapper td:last-child,
.table-wrapper th:last-child {
    width: 90px;
}
