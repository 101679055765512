.support-wrap,
.user-wrap {
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    background-color: #fff;
}
.support-title {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profile-image-sec {
    padding-top: 15px;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.col-24 {
    max-width: 24.99%;
    flex: 0 0 24.99%;
    padding: 0 8px;
}
.col-24.top-spacing {
    margin-top: 15px;
}
.profile-image {
    height: 160px;
}
.profile-image img,
.profile-image figure {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.scroll-1 {
    padding-bottom: 15px;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    /* overflow-x: scroll; */
}
.description-sec {
    padding-top: 15px;
}
.description-sec p {
    margin-top: 15px;
}
.user-inner p {
    word-break: break-all;
}
.profile-image-sec .nodata {
    margin-left: 8px;
    margin-top: 15px;
}
