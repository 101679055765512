.tagged-user.table-wrapper thead th:last-child {
    width: 150px;
}
.tagged-user.table-wrapper td a {
    color: #4ac1e0;
    text-decoration: none;
}
.tagged-user.table-wrapper td a:hover {
    text-decoration: underline;
}
.tagged-user.table-wrapper thead th {
    padding: 10px 15px;
    font-size: 13px;
    font-weight: 600;
    color: #000;
    background: #4ac1e0;
    white-space: nowrap;
}
.tagged-user.table-wrapper td,
.tagged-user.table-wrapper th {
    padding: 5px 15px;
    white-space: nowrap;
}
