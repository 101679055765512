.policys-wrapper {
    position: relative;
    min-height: 100vh;
    padding: 15px;
    background-color: #000000;
}

.footer-img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
}
.footer-img img,
.footer-img figure {
    width: 100%;
    object-fit: contain;
}

.policys-wrapper.terms p strong {
    font-weight: bold;
    letter-spacing: 1.7px;
}
.policys-wrapper p em {
    font-style: italic;
}
.policys-wrapper p {
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    color: #fff;
}

.policys-wrapper ul {
    padding-left: 25px !important;
    list-style: inside !important;
    color: #fff !important;
}

.policys-wrapper ul li {
    color: #fff !important;
}

.policys-wrapper ol {
    padding-left: 25px !important;
    list-style: decimal !important;
    color: #fff !important;
}
@media screen and (max-width: 767px) {
    .policys-wrapper .loader-wrap {
        background: #000;
    }
}
