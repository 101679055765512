.sport-wrap {
    padding: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    background-color: #fff;
}
.permission-form {
    margin-bottom: 0;
    padding: 15px 15px 3px 15px;
}
.sport-title {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title-info {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
}
.btn-group {
    padding: 0;
}
.btn-group button {
    margin-right: 15px;
}
.btn-group button:last-child {
    margin-right: 0;
}
.sport-info {
    max-width: 420px;
    width: 100%;
    flex: 0 0 420px;
}
.sport-text {
    padding-top: 15px;
}
.sport-label {
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1px;
}
.sport-text .MuiTypography-root.MuiTypography-body1 {
    font-size: 15px;
}
.sport-description p {
    margin-bottom: 15px;
    font-size: 15px;
}
.upload-image-2 {
    width: 94px;
    height: 94px;
    flex: 0 0 94px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    background-color: rgb(128 128 128 / 30%);
    padding: 3px;
    border-radius: 3px;
}
.upload-image-2 figure,
.upload-image-2 figure img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.upload-image-2 .upload-icon {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}
.upload-image-2 .close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
}
.close-icon .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #fff;
    color: red;
    transition: all 0.3s ease-in;
    cursor: pointer;
}
.close-icon:hover svg {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-icon svg {
    padding: 4px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #d8d8d8;
    color: #4ac1e0;
    transition: all 0.3s ease-in;
}
.upload-icon:hover svg {
    padding: 4px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.upload-file {
    width: 120px;
    height: 120px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    background-color: #f2f2f2;
}
.upload-file input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}
.upload-file .upload-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.upload-wrap {
    display: flex;
}
