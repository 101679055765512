.policys-wrapper {
    position: relative;
    min-height: 100vh;
    padding: 15px;
    background-color: #000000;
}
.footer-img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
}
.footer-img img,
.footer-img figure {
    width: 100%;
    object-fit: contain;
}
.accordian-title h3.title {
    margin-bottom: 15px;
    padding: 0 15px;
    font-size: 16px;
    color: #979797;
    font-family: "helvetica-blackitalicregular";
    letter-spacing: 0.7px;
}
.accordian-section h3 {
    font-size: 16px;
    color: #fff;
}
.accordian-section p {
    color: #fff;
}
.accordian-section h3.blue p {
    margin-bottom: 15px;
    color: #44c2e3;
}
.MuiAccordionDetails-root h3.blue p strong {
    color: #44c2e3;
}
.policys-wrapper p strong {
    font-weight: bold;
    letter-spacing: 1.7px;
}
.policys-wrapper p em {
    font-style: italic;
}
.policys-wrapper p {
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    color: #fff;
}

@media screen and (max-width: 767px) {
    .policys-wrapper .loader-wrap {
        background: #000;
    }
}
.policys-wrapper ul {
    padding-left: 25px !important;
    list-style: inside !important;
    color: #fff !important;
}

.policys-wrapper ul li {
    color: #fff !important;
}

.policys-wrapper ol {
    padding-left: 25px !important;
    list-style: decimal !important;
    color: #fff !important;
}
.MuiButtonBase-root.MuiAccordionSummary-root {
    background: #1b1b1b !important;
}
.MuiAccordionSummary-root.Mui-expanded {
    border-bottom: 1px solid #282828;
}
.MuiButtonBase-root.MuiAccordionSummary-root p:first-child {
    margin-bottom: 0 !important;
}
.MuiAccordionDetails-root {
    padding: 16px !important;
    background-color: #1b1b1b;
}
.MuiAccordionSummary-content p {
    color: #fff;
}

.MuiTypography-root p a {
    color: #4ac1e0 !important;
}
.MuiAccordionSummary-expandIconWrapper svg {
    color: #fff;
}
.MuiCollapse-root {
    background: #282828;
}
.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters {
    margin-bottom: 12px;
}

.search-wrap-faq {
    width: 450px;
    margin: 15px auto;
    position: relative;
    color: #fff;
}

.search-wrap-faq .MuiInputBase-root.MuiOutlinedInput-root {
    border: 1px solid #595959;
    border-radius: 0;
    color: #fff;
    border: 1px solid gray;
}
.search-wrap-faq .MuiInputBase-root::placeholder {
    color: #fff;
}
.search-wrap .MuiOutlinedInput-input {
    padding-right: 45px;
}
.search-wrap-faq .MuiOutlinedInput-input::placeholder {
    color: #fff;
}
.search-wrap-faq .search-icon {
    width: 25px;
    position: absolute;
    right: 10px;
    top: 21px;
}

@media (max-width: 767px) {
    .search-wrap-faq {
        width: 100%;
    }
}
